import(/* webpackMode: "eager", webpackExports: ["CardBody"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/card/card-body.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/card/card.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Flex"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/flex/flex.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["GridItem"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/grid/grid-item.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Grid"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/grid/grid.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonText"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/skeleton/skeleton-text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Skeleton"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/skeleton/skeleton.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/tmp/build_2adfe956/node_modules/@chakra-ui/react/dist/esm/typography/text.mjs");
